/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/media-has-caption: 0 */
/* eslint jsx-a11y/iframe-has-title: 0 */
import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";

const returnFields = (link, title, modal = false, callback = null) => {
  return (
    <span>
      <span>|</span>
      {modal ? (
        <a
          onClick={() => {
            callback();
          }}
        >
          {title}
        </a>
      ) : (
        <a target="_blank" rel="noreferrer noopener" href={link}>
          {title}
        </a>
      )}
    </span>
  );
};

export default function ReturnAddOnFields({ data }) {
  const [show, setShow] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [showAd, setShowAd] = useState(false);

  const [isPlaying, setPlaying] = useState(false);
  const audioTag = useRef(null);

  const toggleAudio = async () => {
    try {
      if (isPlaying) {
        await audioTag.current.pause();
      } else {
        await audioTag.current.play();
      }
      await setPlaying(!isPlaying);
    } catch (Err) {
      setPlaying(false);
    }
  };

  const printHTML = (source) => {
    return (
      "<html><head><script>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</script></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printMe = (imgURL) => {
    var pwa = window.open("about:blank", "_new");
    pwa.document.open();
    pwa.document.write(printHTML(imgURL));
    pwa.document.close();
  };

  return (
    <>
      {data.website && returnFields(data.website, "Website")}
      {data.email && returnFields("mailto:" + data.email, "Email")}
      {data.company_coupon &&
        returnFields(data.company_coupon, "Coupon", true, () => {
          setShowCoupon(true);
        })}
      {data.youtube && returnFields(data.youtube, "Watch")}
      {data.video &&
        returnFields(data.video, "Video", true, () => {
          setShow(true);
        })}
      {data.audio && (
        <span className="display-inline">
          <span>|</span>
          <a onClick={() => toggleAudio()}>
            <i
              className={"fa fa-volume-" + (isPlaying ? "up" : "down")}
              title="Facebook link"
              aria-hidden="true"
            ></i>
            Listen
          </a>
          <audio ref={audioTag}>
            <source src={data.audio} />
            Your browser does not support the audio tag.
          </audio>
        </span>
      )}
      {data.facebook && returnFields(data.facebook, "Facebook")}
      {data.twitter && returnFields(data.twitter, "Twitter")}
      {data.linkedin && returnFields(data.linkedin, "LinkedIn")}
      {data.instagram && returnFields(data.instagram, "Instagram")}
      {data.company_ad && (
        <>
          {returnFields(data.company_ad, "View Ad", true, () => {
            setShowAd(true);
          })}

          <Modal
            show={showAd}
            onHide={() => setShowAd(false)}
            className="dwld-cpn__modal"
          >
            <Modal.Body>
              <img
                width="100%"
                className="card-img-top"
                src={data.company_ad}
                style={{ objectFit: `contain` }}
                id="adImg"
                alt="N/A"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAd(false)}>
                Close
              </Button>
              <Button
                variant="secondary"
                onClick={() => printMe(data.company_ad)}
              >
                Print
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {data.review_link && returnFields(data.review_link, "Review Link")}

      {data.video && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="dwld-cpn__modal"
          size="lg"
        >
          <Modal.Body>
            <div class="embed-container">
              <iframe
                src={
                  "https://player.vimeo.com/video/" +
                  data.video.split("/").pop()
                }
                frameborder="0"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {data.company_coupon && (
        <Modal
          show={showCoupon}
          onHide={() => setShowCoupon(false)}
          className="dwld-cpn__modal"
        >
          <Modal.Body>
            <img
              width="100%"
              className="card-img-top"
              src={data.company_coupon}
              style={{ objectFit: `contain` }}
              id="couponImg"
              alt="N/A"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCoupon(false)}>
              Close
            </Button>
            <Button
              variant="secondary"
              onClick={() => printMe(data.company_coupon)}
            >
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
